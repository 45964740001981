import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "flex items-center justify-center w-full" }
const _hoisted_2 = { class: "mt-12" }
const _hoisted_3 = { class: "mt-12" }
const _hoisted_4 = { class: "mt-12" }
const _hoisted_5 = { class: "flex items-center" }
const _hoisted_6 = { class: "flex items-center" }
const _hoisted_7 = { class: "flex items-center text-base text-gray-600" }
const _hoisted_8 = {
  key: 0,
  class: "flex items-start"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageHeader = _resolveComponent("PageHeader")!
  const _component_Partners = _resolveComponent("Partners")!
  const _component_Column = _resolveComponent("Column")!
  const _component_Grid = _resolveComponent("Grid")!
  const _component_Picture = _resolveComponent("Picture")!
  const _component_Paragraph = _resolveComponent("Paragraph")!
  const _component_Projects = _resolveComponent("Projects")!
  const _component_ThemeChanger = _resolveComponent("ThemeChanger")!
  const _component_Heading = _resolveComponent("Heading")!
  const _component_LinkList = _resolveComponent("LinkList")!
  const _component_Container = _resolveComponent("Container")!
  const _component_AOS = _resolveComponent("AOS")!
  const _component_TitleAndList = _resolveComponent("TitleAndList")!
  const _component_Anchor = _resolveComponent("Anchor")!
  const _component_MetaInfo = _resolveComponent("MetaInfo")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ThemeChanger, null, {
      default: _withCtx(() => [
        _createVNode(_component_PageHeader, {
          class: "mt-32 home__header lg:mt-48",
          header: _ctx.cms.page_header_heading,
          "sub-header": _ctx.cms.page_header_content,
          "header-span": 5,
          "sub-header-span": 6
        }, null, 8, ["header", "sub-header"]),
        _createVNode(_component_Grid, { class: "mt-16 lg:mt-24" }, {
          default: _withCtx(() => [
            _createVNode(_component_Column, null, {
              default: _withCtx(() => [
                _createVNode(_component_Partners)
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        (_ctx.cms.video)
          ? (_openBlock(), _createBlock(_component_Grid, {
              key: 0,
              fluid: "",
              class: "mt-16 md:mt-24"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                  _createVNode(_component_Picture, {
                    class: "home__video",
                    image: _ctx.cms.video
                  }, null, 8, ["image"])
                ])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        _createVNode(_component_Grid, { class: "mt-16 lg:mt-24" }, {
          default: _withCtx(() => [
            _createVNode(_component_Column, null, {
              default: _withCtx(() => [
                _createVNode(_component_Paragraph, {
                  heading: "",
                  innerHTML: _ctx.cms.related_cases_heading
                }, null, 8, ["innerHTML"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_Projects, {
          data: _ctx.cms.related_cases,
          class: "pb-24 mt-16",
          "second-column-offset": "",
          "use-h2-for-title": ""
        }, null, 8, ["data"])
      ]),
      _: 1
    }),
    _createVNode(_component_ThemeChanger, null, {
      default: _withCtx(() => [
        (_ctx.region === _ctx.Region.CN)
          ? (_openBlock(), _createBlock(_component_Container, {
              key: 0,
              fluid: "",
              class: "pt-0 md:pt-48"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Grid, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_Column, { span: 5 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_Heading, {
                          tag: "h2",
                          innerHTML: _ctx.cms.wwd_heading
                        }, null, 8, ["innerHTML"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_Column, {
                      span: 6,
                      class: "mt-12"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_Paragraph, {
                          innerHTML: _ctx.cms.wwd_description
                        }, null, 8, ["innerHTML"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_Grid, { class: "mt-24 lg:mt-64" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Column, { span: 5 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_Heading, {
                          tag: "h2",
                          class: "overflow-hidden",
                          innerHTML: _ctx.cms.wwd_heading_2
                        }, null, 8, ["innerHTML"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_Column, {
                      span: 2,
                      class: "mt-20 md:mt-24"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_Heading, {
                          tag: "h3",
                          class: "overflow-hidden",
                          innerHTML: _ctx.cms.wwd_sub_heading_1
                        }, null, 8, ["innerHTML"]),
                        _createElementVNode("div", _hoisted_2, [
                          _createVNode(_component_Paragraph, {
                            class: "md:pr-8",
                            innerHTML: _ctx.cms.wwd_sub_paragraph_1
                          }, null, 8, ["innerHTML"])
                        ]),
                        (_ctx.cms.wwd_links_1)
                          ? (_openBlock(), _createBlock(_component_LinkList, {
                              key: 0,
                              class: "mt-12",
                              list: _ctx.cms.wwd_links_1
                            }, null, 8, ["list"]))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_Column, {
                      span: 2,
                      class: "mt-20 md:mt-24"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_Heading, {
                          tag: "h3",
                          class: "overflow-hidden",
                          innerHTML: _ctx.cms.wwd_sub_heading_2
                        }, null, 8, ["innerHTML"]),
                        _createElementVNode("div", _hoisted_3, [
                          _createVNode(_component_Paragraph, {
                            class: "md:pr-8",
                            innerHTML: _ctx.cms.wwd_sub_paragraph_2
                          }, null, 8, ["innerHTML"])
                        ]),
                        (_ctx.cms.wwd_links_2)
                          ? (_openBlock(), _createBlock(_component_LinkList, {
                              key: 0,
                              class: "mt-12",
                              list: _ctx.cms.wwd_links_2
                            }, null, 8, ["list"]))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_Column, {
                      span: 2,
                      class: "mt-20 md:mt-24"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_Heading, {
                          tag: "h3",
                          class: "overflow-hidden",
                          innerHTML: _ctx.cms.wwd_sub_heading_3
                        }, null, 8, ["innerHTML"]),
                        _createElementVNode("div", _hoisted_4, [
                          _createVNode(_component_Paragraph, {
                            class: "md:pr-8",
                            innerHTML: _ctx.cms.wwd_sub_paragraph_3
                          }, null, 8, ["innerHTML"])
                        ]),
                        (_ctx.cms.wwd_links_3)
                          ? (_openBlock(), _createBlock(_component_LinkList, {
                              key: 0,
                              class: "mt-12",
                              list: _ctx.cms.wwd_links_3
                            }, null, 8, ["list"]))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.cms.section_1_title)
          ? (_openBlock(), _createBlock(_component_AOS, {
              key: 1,
              type: "fade-up",
              easing: "ease",
              duration: "800",
              delay: "1000"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Grid, { class: "mt-24" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Column, { span: [ 2, 4 ] }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_5, [
                          _createVNode(_component_Heading, {
                            tag: "h2",
                            class: "text-black",
                            innerHTML: _ctx.cms.section_1_title
                          }, null, 8, ["innerHTML"])
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.cms.section_1_descriptions)
          ? (_openBlock(), _createBlock(_component_AOS, {
              key: 2,
              type: "fade-up",
              easing: "ease",
              duration: "800",
              delay: "1000"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Grid, null, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cms.section_1_descriptions, (item, index) => {
                      return (_openBlock(), _createBlock(_component_Column, {
                        key: index,
                        span: _ctx.cms.section_1_style?.value ?? 2,
                        class: _normalizeClass([ index === 0 ? 'sm:mt-10 mt-20' : 'mt-20' ])
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_Heading, {
                            tag: "h3",
                            "less-margin": ""
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(item?.title), 1)
                            ]),
                            _: 2
                          }, 1024),
                          _createVNode(_component_Paragraph, {
                            class: "text-gray-600 md:pr-10",
                            innerHTML: item?.description
                          }, null, 8, ["innerHTML"])
                        ]),
                        _: 2
                      }, 1032, ["span", "class"]))
                    }), 128))
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.cms.section_2_title)
          ? (_openBlock(), _createBlock(_component_AOS, {
              key: 3,
              type: "fade-up",
              easing: "ease",
              duration: "800",
              delay: "1000"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Grid, { class: "mt-24" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Column, { span: [ 2, 4 ] }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_6, [
                          _createVNode(_component_Heading, {
                            tag: "h2",
                            class: "text-black",
                            innerHTML: _ctx.cms.section_2_title
                          }, null, 8, ["innerHTML"])
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.cms.section_2_descriptions)
          ? (_openBlock(), _createBlock(_component_AOS, {
              key: 4,
              type: "fade-up",
              easing: "ease",
              duration: "800",
              delay: "1000"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Grid, null, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cms.section_2_descriptions, (item, index) => {
                      return (_openBlock(), _createBlock(_component_Column, {
                        key: index,
                        span: _ctx.cms.section_2_style?.value ?? 2,
                        class: _normalizeClass([ index === 0 ? 'sm:mt-10 mt-20' : 'mt-20' ])
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_Heading, {
                            tag: "h3",
                            "less-margin": ""
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(item?.title), 1)
                            ]),
                            _: 2
                          }, 1024),
                          _createVNode(_component_Paragraph, {
                            class: "text-gray-600 md:pr-10",
                            innerHTML: item?.description
                          }, null, 8, ["innerHTML"])
                        ]),
                        _: 2
                      }, 1032, ["span", "class"]))
                    }), 128))
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cms.tech, (feature, index) => {
          return (_openBlock(), _createBlock(_component_Container, {
            fluid: "",
            key: index
          }, {
            default: _withCtx(() => [
              _createVNode(_component_TitleAndList, {
                title: feature.title,
                list: feature.items,
                index: index,
                class: "mt-28"
              }, null, 8, ["title", "list", "index"])
            ]),
            _: 2
          }, 1024))
        }), 128)),
        (_ctx.cms.section_3_title && _ctx.cms.section_3_content)
          ? (_openBlock(), _createBlock(_component_Container, {
              key: 5,
              class: "grid mx-auto my-24 md:my-48"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Heading, {
                  tag: "h2",
                  "less-margin": ""
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.cms.section_3_title), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_Paragraph, {
                  class: "text-gray-600 md:pr-10",
                  innerHTML: _ctx.cms.section_3_content
                }, null, 8, ["innerHTML"])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.region === _ctx.Region.CN)
          ? (_openBlock(), _createBlock(_component_Grid, {
              key: 6,
              "reverse-on-mobile": "",
              class: "md:pt-48",
              centered: ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Column, { span: 2 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Heading, {
                      tag: "h2",
                      class: "sm:mt-12 md:mt-0 text-2xl-mo md:text-2xl",
                      innerHTML: _ctx.cms.au_heading
                    }, null, 8, ["innerHTML"]),
                    _createVNode(_component_Paragraph, {
                      class: "mb-12 text-base text-gray-600 md:pr-8 sm:mt-12 md:mt-16",
                      innerHTML: _ctx.cms.au_paragraph
                    }, null, 8, ["innerHTML"]),
                    _createElementVNode("div", _hoisted_7, [
                      _createVNode(_component_Anchor, {
                        route: _ctx.Route.About,
                        underlined: "",
                        "show-arrow": ""
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.cms.au_label), 1)
                        ]),
                        _: 1
                      }, 8, ["route"])
                    ])
                  ]),
                  _: 1
                }),
                _createVNode(_component_Column, {
                  span: 2,
                  class: "sm:mt-24 md:hidden"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Picture, {
                      image: _ctx.cms.au_images[0],
                      width: "100%",
                      height: "auto"
                    }, null, 8, ["image"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_Column, {
                  span: [ 2, 3 ],
                  push: [ 0, 0, 1 ],
                  container: "",
                  class: "sm:hidden md:block"
                }, {
                  default: _withCtx(() => [
                    (_ctx.cms.au_images)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                          _createVNode(_component_Picture, {
                            image: _ctx.cms.au_images[0],
                            overflow: ""
                          }, null, 8, ["image"])
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    (_ctx.cms.seo)
      ? (_openBlock(), _createBlock(_component_MetaInfo, {
          key: 0,
          seo: _ctx.cms.seo,
          "structured-data": _ctx.cms.seo_structured_data,
          published: _ctx.cms.date,
          modified: _ctx.cms.last_modified
        }, null, 8, ["seo", "structured-data", "published", "modified"]))
      : _createCommentVNode("", true)
  ], 64))
}