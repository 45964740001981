import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "link-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Anchor = _resolveComponent("Anchor")!
  const _component_Paragraph = _resolveComponent("Paragraph")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("ul", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item, index) => {
        return (_openBlock(), _createElementBlock("li", {
          key: index,
          class: "link-list__item py-5 border-b border-pure-black"
        }, [
          (item.type === 'link' )
            ? (_openBlock(), _createBlock(_component_Anchor, {
                key: 0,
                route: { name: _ctx.Route.Services, params: { slug: item?.entry?.slug ?? '' } },
                bold: false
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(item.label), 1)
                ]),
                _: 2
              }, 1032, ["route"]))
            : (_openBlock(), _createBlock(_component_Paragraph, {
                key: 1,
                innerHTML: item.text
              }, null, 8, ["innerHTML"]))
        ]))
      }), 128))
    ])
  ]))
}