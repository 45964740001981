

    import { defineComponent } from 'vue'
    import Anchor from './Anchor.vue'
    import Paragraph from './Paragraph.vue'
    import { Route } from '../enums/Route'

    export default defineComponent({
        name: 'TitleAndList',
        components: {
            Anchor,
            Paragraph
        },
        props: {
            list: { type: Array, required: true },
            index: { type: Number, default: 0 }
        },
        setup(props) {

            return {
                Route
            }

        }
    })

